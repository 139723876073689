<!-- Sign In Form -->
<div class="container my-5 py-5 ">
    <div class="row mt-5 mb-4">
        <div class="col-xl-4 col-lg-4 col-12 offset-xl-4 offset-lg-4 mb-4">
            <div class="card access-form pb-2 mb-4">
                <div class="card-header text-center py-4">
                    <!-- <img src="assets/uploads/logo.png" alt="" class="img-fluid logo"> -->
                    <h1 class="heading-24-semibold text-white mb-0 mt-2">SIGN IN</h1>
                </div>
                <div class="card-body px-4 ">
                    <form class="mt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input  type="email" formControlName="email"  class="form-control" id="email-field"
                                        placeholder="john@example.com">
                                    <label for="email-field">E-Mail* </label>
                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)" class="error">
                                        <div *ngIf="formControls.email.errors.required">Please enter your email.</div>
                                    </div>
                                    <!--<span class="input-span">Required</span>-->
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input *ngIf="showPassword" type="text" formControlName="password" class="form-control" id="password-field"
                                        placeholder="Password">
                                        <input *ngIf="!showPassword" type="password" formControlName="password" class="form-control" id="password-field"
                                        placeholder="Password">
                                    <label for="password-field">Password* </label>
                                    <span class="input-span cursor-pointer" (click)="toggleShow()">
                                        <i *ngIf="showPassword" class="fas fa-eye" ></i>
                                        <i *ngIf="!showPassword" class="fas fa-eye-slash" ></i>
                                    </span>
                                    <div *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)" class="error">
                                        <div *ngIf="formControls.password.errors.required">Please enter your password.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xl-7 col-lg-7 col-6">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="remember-me">
                                    <label class="form-check-label font-14" for="remember-me">Remember me</label>
                                  </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-6 text-end">
                                <a routerLink="/forgot_password" class="forgot-password-anchor text-dark font-14">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-orange btn-block" type="submit" [disabled]="!loginForm.valid || submitted">Sign In</button>
                                </div>
                            </div>
                           
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12 text-center">
                                <p class="mb-0 font-14">Don't have an Ejtimaa account yet? <a routerLink="/register">Sign Up</a></p>
                            </div>
                        </div>
                    </form>
                    <!-- <div class="row mt-4">
                        <div class="col-xl-12 col-lg-12 col-12">
                            <div class="d-grid gap-2">
                                <button type="button" class="btn btn-info text-white" type="button" (click)="googleSignin()" >Google Signin</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->
<!--<div class="container mt-5">
    <div class="row">
        <div class="col-lg-6 col-sm-6 col-12 offset-lg-3 offset-sm-3">
            <div class="card">
                <h5 class="card-header dark-text text-center py-4">
                    <strong>Login</strong>
                </h5>
                <div class="card-body px-lg-5 pt-0">
                    <div class="col-12 offset-lg-3 offset-sm-3" *ngIf="isDemo=='demo'">
                        <div >E-mail: demo@ejtimaa.com</div>
                        <div>Password: Demo@123</div>
                       </div> 
                    <form class="text-center" style="color: #757575" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                       
                           <div class="md-form">
                            <input type="email" formControlName="email" id="materialLoginFormEmail" class="form-control" />
                            <label for="materialLoginFormEmail">E-mail</label>
                            <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)" class="error">
                                <div *ngIf="formControls.email.errors.required">Please enter your email.</div>
                            </div>
                        </div>

                        <div class="md-form">
                            <input type="password" formControlName="password" id="materialLoginFormPassword" class="form-control" />
                            <label for="materialLoginFormPassword">Password</label>
                            <div *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)" class="error">
                                <div *ngIf="formControls.password.errors.required">Please enter your password.</div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-around">
                            <!-- <div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="materialLoginFormRemember" />
                                    <label class="form-check-label" for="materialLoginFormRemember">Remember me</label>
                                </div>
                            </div> --
                            <div>
                                <a routerLink="/forgot_password">Forgot password?</a>
                            </div>
                        </div>
                        <button class="btn btn-outline-deep-blue btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit" [disabled]="!loginForm.valid || submitted">Sign in</button>

                        <p>
                            Not a member?
                            <a routerLink="/register">Register</a>
                        </p>

                        <!-- <p>or sign in with:</p>
                        <a type="button" class="btn-floating btn-fb btn-sm">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a type="button" class="btn-floating btn-tw btn-sm">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a type="button" class="btn-floating btn-li btn-sm">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a type="button" class="btn-floating btn-git btn-sm">
                            <i class="fab fa-github"></i>
                        </a> --
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>-->


