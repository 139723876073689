<div style="background-position: center;background-repeat: no-repeat; 
background-size: cover; "><!--background-image: url('assets/images/pexels-anna-shvets-4226140@2x.png');-->
          <!-- Sign In Form -->
          <div class="container my-5 py-5 ">
            <div class="row mt-5 mb-5">
                <div class="col-xl-4 col-lg-4 col-12 offset-xl-4 offset-lg-4 mb-5">
                    <div class="card access-form pb-2">
                        <div class="card-header text-center py-4">
                            <!-- <img src="assets/uploads/logo.png" alt="" class="img-fluid logo"> -->
                            <h1 class="heading-24-semibold text-white mb-0 mt-2">REQUEST DEMO</h1>
                        </div>
                        <div class="card-body px-4">
                            <form class="mt-3" style="color: #757575" [formGroup]="registerForm"
                                    (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="name" id="materialRegisterFormame"
                                                placeholder="Your Name">
                                            <label for="firstname-field">Your Name</label>
                                            <span class="input-span">Required</span>
                                            <div *ngIf="formControls.name.invalid && (formControls.name.dirty || formControls.name.touched)"
                                            class="error input-span">
                                            <ng-container *ngIf="formControls.name.errors.required"> Please
                                                enter your name. </ng-container>
                                            <ng-container *ngIf="formControls.name.errors.maxlength">
                                                Max characters limit is {{
                                                formControls.name.errors.maxlength.requiredLength }}
                                                characters.
                                            </ng-container>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-12">
                                        <div class="form-floating">
                                            <input type="text" formControlName="company_name" class="form-control" id="materialRegisterFormcompanyName"
                                                placeholder="Company Name">
                                            <label for="lastname-field">Company Name</label>
                                            <span class="input-span">Required</span>
                                            <div *ngIf="formControls.company_name.invalid && (formControls.company_name.dirty || formControls.company_name.touched)"
                                            class="error input-span">
                                            <ng-container *ngIf="formControls.company_name.errors.required"> Please
                                                enter your company name. </ng-container>
                                            <ng-container *ngIf="formControls.company_name.errors.maxlength">
                                                Max characters limit is {{
                                                formControls.company_name.errors.maxlength.requiredLength }}
                                                characters.
                                            </ng-container>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-12">
                                        <div class="form-floating">
                                            <input type="email" formControlName="email" class="form-control" id="materialRegisterFormEmail"
                                                placeholder="john@example.com">
                                            <label for="email-field">Email </label>
                                            <span class="input-span">Required</span>
                                            <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)"
                                            class="error input-span">
                                            <ng-container *ngIf="formControls.email.errors.required"> Please enter your
                                                email. </ng-container>
                                            <ng-container
                                                *ngIf="formControls.email.errors.email || formControls.email.errors.pattern">
                                                Please enter a valid email address. </ng-container>
                                            <ng-container *ngIf="formControls.email.errors.email_unique"> Email already
                                                exist. </ng-container>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="phone" id="materialRegisterFormphone"
                                                placeholder="Phone Number">
                                            <label for="password-field">Phone Number </label>
                                            <span class="input-span">Required</span>
                                            <div *ngIf="formControls.phone.invalid && (formControls.phone.dirty || formControls.phone.touched)"
                                            class="error input-span">
                                            <ng-container *ngIf="formControls.phone.errors.required"> Please enter your
                                                phone. </ng-container>
                                           
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-12">
                                        <div class="d-grid gap-2">
                                            <button type="submit" class="btn btn-orange btn-block">Request Demo</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-xl-12 col-lg-12 col-12 text-center">
                                        <p class="mb-0 font-14">Already have an Ejtimaa account yet? <a routerLink="/login">Sign In</a></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
             </div>
           <!-- End -->
        </div>
</div>