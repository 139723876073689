<div class="container my-5 py-5">
    <div class="row mt-5">
        <div class="col-xl-6 col-lg-6 col-12 offset-xl-3 offset-lg-3">
            <div class="card access-form pb-2">
                <div class="card-header text-center py-4">
                    <img src="assets/uploads/logo.png" alt="" class="img-fluid logo">
                    <h1 class="heading-24-semibold text-white mb-0 mt-2">CONTACT US</h1>
                </div>
                <div class="card-body px-4">
                    <form class="mt-3" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input  type="email" formControlName="email"  class="form-control" id="email-field"
                                        placeholder="john@example.com">
                                    <label for="email-field">E-Mail* </label>
                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)" class="error">
                                        <div *ngIf="formControls.email.errors.required">Please enter your email.</div>
                                    </div>
                                    <!--<span class="input-span">Required</span>-->
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="text" formControlName="name" class="form-control" id="name-field"
                                        placeholder="Name">
                                    <label for="password-field">Name </label>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="text" formControlName="phone" class="form-control" id="phone-field"
                                        placeholder="Phone">
                                    <label for="password-field">Phone </label>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <textarea formControlName="message" class="form-control rounded-0" mdbInput id="exampleFormControlTextarea2"
        rows="3" placeholder="Message"></textarea>
        <label for="password-field">Message </label>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-primary btn-block" type="submit" [disabled]="!contactForm.valid || submitted">Send</button>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
