import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactUsService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder,
    private _contactusService:ContactUsService,
    private toastr: ToastrService,
    private router: Router) {

    
    }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      email: ['',
          [
              Validators.required,
              Validators.maxLength(40)
          ]
      ],
      name: ['',
          [
              Validators.required,
              Validators.maxLength(40)
          ]
          
      ],
      phone:['',[]],
      message: ['',
          [  ]
    ]
        
  });
}

get formControls() {
return this.contactForm.controls;
}

  onSubmit() {
    this.submitted = true;
    this._contactusService.sendContactUs(this.contactForm.value).subscribe(data => {
      // alert('Your message has been sent.');
      this.contactForm.reset();
      //this.disabledSubmitButton = true;
      this.toastr.success(data.message, '');
      this.router.navigateByUrl('');
    }, error => {
      console.log('Error', error);
    });
  }

}
