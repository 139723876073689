import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HttpInterceptorProviders } from './interceptors';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
// import { ClipboardModule } from 'ng2-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { NgModule } from '@angular/core';


import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { HeaderComponent } from './components/partials/header/header.component';
import { RegisterComponent } from './components/register/register.component';
import { InvitedRegisterComponent } from './components/invitedregister/invitedregister.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { DemoComponent } from './components/demo/demo.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';
import { FooterComponent } from './components/partials/footer/footer.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AddToCalendarModule } from 'add-events-to-google-calendar';
import {
  GoogleApiModule,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
} from 'ng-gapi';

let gapiClientConfig: NgGapiClientConfig = {
  client_id:
    '547934783367-gtv7tubfekcts41oj2lgsuumg70g88ek.apps.googleusercontent.com',
  discoveryDocs: [
    'https://analyticsreporting.googleapis.com/$discovery/rest?version=v4',
  ],
  scope: [
    'https://www.googleapis.com/auth/gmail.readonly',
    'https://www.googleapis.com/auth/calendar'
  ].join(' '),
};

const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    InvitedRegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    InvitationComponent,
    DemoComponent,
    FooterComponent,
    ContactUsComponent,
    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AddToCalendarModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1
    }),
    SocketIoModule.forRoot(config),
    LoadingBarHttpClientModule,
    SweetAlert2Module.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
  ],
  
  providers: [HttpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
