import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";
import {
  Router
} from "@angular/router";
import { Subject } from "rxjs";
import { Location } from "@angular/common";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  registerForm: FormGroup;
  submitted = false;
  loggedUser: any;
  showPassword=false;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private location: Location
  ) {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      first_name: ["", [Validators.required, Validators.maxLength(40)]],
      last_name: ["", [Validators.required, Validators.maxLength(40)]],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(40),
          Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
        ],
        this.isEmailUnique.bind(this),
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(11),
          // Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
        ],
      ],
      /* confirm_password: ['',
                            [
                                Validators.required,
                                this.passwordMatcher.bind(this)
                            ]
                        ],
                        accept: [false, Validators.requiredTrue],*/
    });
  }

  get formControls() {
    return this.registerForm.controls;
  }

  private isEmailUnique(control: FormControl) {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.authService
          .uniqueCheck(control.value, "email")
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => {
              resolve(null);
            },
            () => {
              resolve({ email_unique: true });
            }
          );
      }, 500);
    });
    return promise;
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
      this.registerForm &&
      control.value !== this.registerForm.controls.password.value
    ) {
      return { passwordNotMatch: true };
    }
    return null;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.value.first_name == "") {
      this.toastr.error("Enter First Name", "");
    } else if (this.registerForm.value.last_name == "") {
      this.toastr.error("Enter Last Name", "");
    } else if (this.registerForm.value.email == "") {
      this.toastr.error("Enter valid Email", "");
    } else if (this.registerForm.value.password.length < 6) {
      this.toastr.error("Enter Password", "");
      /*}else if(this.registerForm.value.confirm_password=="") {
            this.toastr.error("Enter Confirm Password", '');*/
    } else {
     
      if (!this.registerForm.controls.password.errors) {
        let userDetails = {
          ...this.registerForm.value
        };

        
        this.authService
          .register(userDetails)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (data) => {
              sessionStorage.invitedDetails = "";
              this.registerForm.reset();
              this.toastr.success(data.message, "");
              this.router.navigate(["/login"]);
            },
            (error) => {
              this.toastr.error(error.message, "");
              this.submitted = false;
            }
          )
          .add(() => {
            this.submitted = false;
          });
      } else {
          this.toastr.error("Enter valid Password", "");
          this.submitted = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
