// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://ejtimaa.com/api/v1",
  // baseUrl: "http://localhost:3000/api/v1",
  socketUrl: "https://ejtimaa.com",
  secretKey: "ejitimaa_bussiness",
  googleClientId:
    "547934783367-gtv7tubfekcts41oj2lgsuumg70g88ek.apps.googleusercontent.com",
  googleClientKey: "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",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
