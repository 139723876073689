<div class="container mt-5 py-5 " style="min-height: 500px;">
    <div class="row mt-5 pb-5">
        <div class="col-lg-3 col-sm-12 col-12 mt-5 "></div>
            <div class="col-lg-6 col-sm-12 col-12 ">
            <div class="card access-form mt-5" >
                <h5 class="card-header text-white text-center  py-4">
                    <strong>Reset Password</strong>
                </h5>

                <div class="card-body px-4 py-4">
                    <form class="text-left" style="color: #757575" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="md-form">
                            <label for="materialLoginFormEmail">Password</label>
                            <input type="password" formControlName="password" id="materialLoginFormEmail" class="form-control" />
                           
                            <div *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)" class="error">
                                <div *ngIf="formControls.password.errors.required">
                                    Please enter your password.
                                </div>
                                <div *ngIf="formControls.password.errors.minlength">
                                    Please enter at least {{formControls.password.errors.minlength.requiredLength}} characters.

                                </div>
                                <div *ngIf="formControls.password.errors.maxlength">
                                    Please enter no more than {{formControls.password.errors.maxlength.requiredLength}} characters.
                                </div>
                                <div *ngIf="formControls.password.errors.pattern && !formControls.password.errors.minlength">
                                    Password should contains a lowercase, a uppercase character and a digit.
                                </div>
                            </div>
                        </div>

                        <div class="md-form text-left">
                            <label for="materialLoginFormPassword">Confirm Password</label>
                            <input type="password" formControlName="confirm_password" id="materialLoginFormPassword" class="form-control" />
                           
                            <div *ngIf="formControls.confirm_password.invalid && (formControls.confirm_password.dirty || formControls.confirm_password.touched)" class="error">
                                <div *ngIf="formControls.confirm_password.errors.required">
                                    Please confirm your password.
                                </div>
                                <div *ngIf="formControls.confirm_password.errors.passwordNotMatch && !formControls.confirm_password.errors.required">
                                    please enter same password.
                                </div>
                            </div>
                        </div>
                        <div class="md-form text-center">
                        <button class="btn btn-outline-deep-blue btn-rounded btn-block my-4 waves-effect z-depth-0" type="submit" [disabled]="!resetPasswordForm.valid || submitted">Submit</button>
                   </div>   
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-12 col-12 "></div>
    </div>
</div>
