import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
}) 
export class ForgotPasswordComponent implements OnInit, OnDestroy { 
    
    private unsubscribe$ = new Subject();
	forgotPasswordForm: FormGroup;
	submitted = false;

    constructor(
		private authService: AuthService,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private router: Router
	) {}

    ngOnInit(): void { 
        this.forgotPasswordForm = this.fb.group({
            email: ['',
                [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(40),
                    Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
                ],
                this.isUserExist.bind(this)
            ],
        });
    }

    private isUserExist(control: FormControl) {
        const q = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.authService.userExist(control.value).pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    resolve(null);
                }, () => { resolve({ user_exist: true }); });
            }, 500);
        });
        return q;
    }

    get formControls() { 
		return this.forgotPasswordForm.controls; 
	}

    onSubmit() {
        this.authService.forgotPassword(this.forgotPasswordForm.value).pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
            this.forgotPasswordForm.reset();
            this.router.navigate(['/login']).then(() => {
                this.toastr.success(data.message, '');
            });
        },
            error => {
                this.toastr.error(error.message, '');
            }
        );
    }

    ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
