<div class="container pt-5  my-5" [style.min-height.px]="screenHeight-238">
    <div class="row">
        <div class="col-lg-6 col-sm-6 col-12 offset-lg-3 offset-sm-3">
            <div class="card">

                <div class="card-body text-center">
                    <h2 style="margin-top: 20px;">Join Meeting</h2>
                    <p>To join a meeting, enter the meeting code provided by the organizer</p>
                    <form class="mt-4" [formGroup]="joinForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <input type="text" formControlName="slug" class="form-control" placeholder="Meeting Code">
                            <div *ngIf="formControls.slug.invalid && (formControls.slug.dirty || formControls.slug.touched)" class="error">
                                <ng-container *ngIf="formControls.slug.errors.required"> Please enter your slug. </ng-container>
                                <ng-container *ngIf="formControls.slug.errors.invalid_slug"> Invalid meetng code. </ng-container>
                            </div>
                        </div>
                        <button *ngIf="this.isLoggedIn" class="btn btn-primary btn-deep-orange px-4 mt-2 waves-effect waves-light" [disabled]="!joinForm.valid || submitted">Join Meeting</button>
                        <button *ngIf="!this.isLoggedIn" [swal]="guestModal" class="btn btn-primary btn-deep-orange px-4 mt-2 waves-effect waves-light" [disabled]="!joinForm.valid || submitted">Join Meeting</button>
                        <!--<button [hidden]="isLoggedIn" [swal]="guestModal" class="btn btn-primary btn-deep-orange px-4 mt-2 waves-effect waves-light">Join As Guest</button>-->
                    </form>
                    <swal
                        #guestModal
                        [showCancelButton]="true"
                        [focusCancel]="true"
                        title="Join As Guest"
                        input="text"
                        inputPlaceholder="Your name"
                        (confirm)="handleSwalSubmit($event)"
                        [inputValidator]="swalInputValidator"
                        >
                    </swal>
                    <swal
                        #authSwal
                        title="Access Denied"
                        text="Please login or join as guest."
                        type="warning">
                    </swal>
                </div>
            </div>
        </div>
    </div>
</div>
