import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	
	private unsubscribe$ = new Subject();
	resetPasswordForm: FormGroup; 
	submitted = false;

    constructor( 
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private toastr: ToastrService,
        private route: ActivatedRoute
    ) {

    }
	
	ngOnInit(): void { 
		this.resetPasswordForm = this.fb.group({
			password: ['',
				[
					Validators.required,
					// Validators.minLength(6),
					// Validators.maxLength(11),
					// Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)
				]
			],
			confirm_password: ['',
				[
					Validators.required,
					this.passwordMatcher.bind(this)
				]
			],
			token: ['']
		});
		const token = this.route.snapshot.paramMap.get('token');
		console.log("token", token);
		if (token) {
			this.resetPasswordForm.get('token').setValue(token);
			this.authService.checkResetLink(token).pipe(takeUntil(this.unsubscribe$))
				.subscribe(data => {
					console.log("data", data);
				if (!data.status) {
					this.router.navigate(['/login']).then(() => {
						this.toastr.error(data.message, '');
					});
				}
			},
				error => {console.log("error", error);
					this.router.navigate(['/login']).then(() => {
						this.toastr.error(error.message, '');
					});
				}
			);
		}
	}

	private passwordMatcher(control: FormControl): { [s: string]: boolean } {
		if (this.resetPasswordForm && (control.value !== this.resetPasswordForm.controls.password.value)) {
			return { passwordNotMatch: true };
		}
		return null;
	}

	get formControls() { 
		return this.resetPasswordForm.controls; 
	}

	onSubmit() {
		this.authService.resetPassword(this.resetPasswordForm.value).pipe(takeUntil(this.unsubscribe$))
		.subscribe(data => {
			this.resetPasswordForm.reset();
			this.router.navigate(['/login']).then(() => {
				this.toastr.success(data.message, '');
			});
		},
			error => {
				this.toastr.error(error.message, '');
			}
		);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

}
