import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { AuthService, MeetingService, StorageService } from 'src/app/services';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import $ from 'jquery';

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('recodingPlugin',{ static: true }) private recodingPlugin: SwalComponent;
    @ViewChild('hamburger') hamburger: ElementRef<HTMLElement>;
	private unsubscribe$ = new Subject();
	loggedUser: any;
	showVideoIcon = true;
    userImage: string;
    deviceInfo = null;
    isMobile = null;
    ejPass="12";

	constructor(
		private router: Router,
		private toastr: ToastrService,
        private authService: AuthService,
        private elementRef: ElementRef,
		private meetingService: MeetingService,
        private storageService: StorageService,
        private deviceService: DeviceDetectorService
	) {}

    ngOnInit(): void {
       
        if(this.router.url==='/meeting/start'){
            this.loggedUser = this.authService.getUser();
        }else if(this.router.url==='/meeting/upcomming'){
            this.loggedUser = this.authService.getUser();
        }

		this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
			if (event instanceof NavigationEnd) {
                this.loggedUser = this.authService.getUser();
				this.showVideoIcon = event.url.includes('/meeting/room') ? false : true;
               
				if(this.loggedUser) {
                    const user = this.authService.getUser();
                    if(user.image_url) {
                        console.log('user_url',user.image_url);
                        this.userImage = user.image_url;
                    }
                   
                    this.getRoomDetails();
				}
			}
		});

        this.ejPass = localStorage.getItem("ejpass");
		this.meetingService.onStartMeeting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			this.goToMeeting();
		});
	}

	goToMeeting(): void {
        // if(this.deviceInfo && (this.deviceInfo.browser == 'Chrome')){
        //     this.recodingPlugin.fire();
        // } else {
        //     this.onAfterClose();
        // }
        const meeting = this.storageService.get('ejtimaa-meet');
        this.meetingService.directMeeting(meeting.meeting_slug).pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
			this.router.navigate([`/meeting/room/${meeting.meeting_slug}`]);
        },
        err => {
            this.toastr.error(err.message, '');
        });

    }
  

    onAfterClose() {
        const meeting = this.storageService.get('ejtimaa-meet');
        this.meetingService.directMeeting(meeting.meeting_slug).pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
			this.router.navigate([`/meeting/room/${meeting.meeting_slug}`]);
        },
        err => {
            this.toastr.error(err.message, '');
        });
    }

	getRoomDetails(): void {
		this.meetingService.getMeetingDetails().pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
			this.storageService.set('ejtimaa-meet',data.meeting);
        },
        err => {
            this.toastr.error(err.message, '');
        });
	}

	logout() {
		this.authService.logout();
		this.router.navigate(['/login']);
    }

    ngAfterViewInit() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        if(this.isMobile) {
            this.elementRef.nativeElement
            .querySelector('#navbarSupportedContent')
            .addEventListener('click', this.handleHamburger.bind(this));
        }

    }

    handleHamburger(event) {
        const element: HTMLElement = this.hamburger.nativeElement;
        element.click();
    }

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

    startDemo() { localStorage.isDemo = "demo"; }
}
