
<!-- Footer -->
<div class="container-fluid footer py-4 ">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-12 text-center">
                <ul class="social-ul">
                    <li>
                        <a href=" https://www.facebook.com/Ejtimaa-103205518754829" target="_blank">
                            <img src="assets/uploads/facebook.svg" alt="" class="img-fluid" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/ejtimaa" target="_blank">
                            <img src="assets/uploads/twitter.svg" alt="" class="img-fluid" />
                        </a>
                    </li>
                    <li>
                        <a href=" https://www.linkedin.com/company/ejtimaa" target="_blank">
                            <img src="assets/uploads/linkedin.svg" alt="" class="img-fluid" />
                        </a>
                    </li>
                    <li>
                        <a href=" https://www.youtube.com/channel/UCbOLoTSrmZgJ2nseDQjRaUw" target="_blank">
                            <img src="assets/uploads/youtube.png" alt="" class="img-fluid" />
                        </a>
                    </li>
                    <li>
                        <a href="https://instagram.com/ejtimaa_uae?utm_medium=copy_link" target="_blank">
                            <img src="assets/uploads/instagram.png" alt="" class="img-fluid" style="background: white;
                            border-radius: 50px;" />
                        </a>
                    </li>
                </ul>
                <p class="text-white mt-4 mb-0">
                    © 2021 Ejtimaa. | <a href="" class="text-white">Ejtimaa.com</a> | <a href="" class="text-white">+971568884209</a> | <a href="" class="text-white">Privacy</a>
                </p>
            </div>
        </div>
    </div>
</div>
<!-- End -->
