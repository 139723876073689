import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    SweetAlert2Module,
    PickerModule,
    NgSelectModule
  ],
  exports:[
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    BsDatepickerModule,
    TimepickerModule,
    SweetAlert2Module,
    PickerModule,
    NgSelectModule
  ]
})
export class SharedModule { }
