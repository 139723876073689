import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";


@Injectable({
    providedIn: "root",
})
export class ContactUsService {
    serverUrl = environment.baseUrl;
	errorData: {};
    redirectUrl: string;
    constructor(
        private http: HttpClient,        
    ) {}

    sendContactUs(data) {
        return this.http.post<any>(`${this.serverUrl}/contacus`,data)
        .pipe(
            catchError(this.handleError)
        );
    }

    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('An error occurred:', errorResponse.error.message);
        } else {
            console.error(`Backend returned code ${errorResponse.error.status}, ` + `body was: ${errorResponse.error.message}`);
        }
        this.errorData = {
            error: errorResponse.error.error,
            message: (errorResponse.error.message) ? errorResponse.error.message : 'Something went wrong'
        };
        if(errorResponse.status == 401){
            localStorage.removeItem('currentUser');
        }
        return throwError(this.errorData);
    }
}