<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/uploads/logo.png" alt="" class="img-fluid">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse flex-column" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-lg-0">
                <li class="nav-item" *ngIf="!loggedUser" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                    <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/login">
                        <img src="assets/uploads/user-round-icon.svg" alt="" class="img-fluid"  /> Login
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                    <a class="nav-link" routerLink="/contact" href="javascript:;">
                        <img src="assets/uploads/mail-icon.svg" alt="" class="img-fluid" /> sales@knowledgekafe.com
                    </a>
                </li>
                <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                    <a class="nav-link" href="tel:+971568884209">
                        <img src="assets/uploads/phone-icon.svg" alt="" class="img-fluid" />
                         <span>+971568884209</span>
                    </a>
                </li>
                <!-- <li class="nav-item dropdown translation-links">
                    <a class="nav-link dropdown-toggle" href="javascript:;"  (click)="translateLanguage('en');" id="en" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                         </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" href="javascript:;" id="ar" (click)="translateLanguage('ar');">
                             </a>
                        
                    </div>
                </li> -->
                <li class="nav-item dropdown" *ngIf="loggedUser">
                    <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
                        <ng-container *ngIf="userImage else userPlaceholder">
                            <img class="profileImage" [src]="userImage" alt="">
                        </ng-container>
                        <ng-template #userPlaceholder>
                            <img class="profileImage" src="/assets/images/user.png" alt="" style="background-color: white;">
                        </ng-template>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item waves-effect waves-light isNavLink" routerLink="/user/profile" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">Profile</a>
                      <a class="dropdown-item waves-effect waves-light isNavLink" (click)="logout()" href="javascript:;" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">Logout</a>
                    </div>
                  </li>
                
                <!--<li class="nav-item" *ngIf="loggedUser">
                    <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/login" (click)="logout()" href="javascript:;">
                        <img src="assets/uploads/user-round-icon.svg" alt="" class="img-fluid"  /> Logout
                    </a>
                </li>-->
                <!--<li *ngIf="!loggedUser" class="nav-item d-block">
                    <a href="" (click)="startDemo()" routerLink="/fordemo" class="btn btn-orange d-block">REQUEST A DEMO</a>
                </li>-->
                <li *ngIf="!loggedUser" class="nav-item d-block" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                    <a routerLink="/register" href="javascript:;" class="btn btn-orange d-block">SIGN UP</a>
                </li>
                
                </ul>
                <ul *ngIf="loggedUser" class="navbar-nav ms-auto mb-lg-0">
                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                        <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/meeting/start" href="javascript:;">
                            MEETING
                        </a>
                    </li>
                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                        <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/meeting/upcomming" href="javascript:;">
                            UPCOMING MEETING
                        </a>
                    </li>
                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                        <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/meeting/history" href="javascript:;">
                            MEETING HISTORY
                        </a>
                    </li>
                    <li class="nav-item" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
                        <a class="nav-link" routerLinkActive="active font-bold"  [routerLinkActiveOptions]="{ exact: true }" routerLink="/meeting/channel" href="javascript:;">
                            CHANNEL
                        </a>
                    </li>
                </ul>
                </div>
        </div>
</nav>

<!--<nav class="navbar navbar-expand-lg navbar-dark desertNav">
    <div class="container">
        <a class="navbar-brand mob-show" routerLink="/"><img src="/assets/images/E&J LOGO-1.png" class="logo-style" /> </a>
        <button #hamburger class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- <ul class="navbar-nav mr-auto" [hidden]="showVideoIcon"> --
                <ul class="navbar-nav mr-auto" style="width: 62%;">
                <ng-container *ngIf="(loggedUser && loggedUser.is_guest == false) else homeBlock">
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link waves-effect waves-light isNavLink" routerLink="/meeting/start"><b>Meeting</b></a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link waves-effect waves-light isNavLink" routerLink="/meeting/upcomming"><b>Upcoming meetings</b></a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link waves-effect waves-light isNavLink" routerLink="/meeting/history"><b>Meeting history</b></a>
                    </li>
                </ng-container>
                <ng-template #homeBlock>
                    <li class="nav-item">
                        <a class="nav-link waves-effect waves-light isNavLink" routerLink="/"><img src="/assets/images/E&J LOGO-1.png" class="logo-style" /></a>
                    </li>
                </ng-template>
            </ul>
            <ul class="navbar-nav ml-auto">

                <ng-container *ngIf="(loggedUser && loggedUser.is_guest == false) else loginBlock">
                    <li class="nav-item pr-3 pt-2" [hidden]="showVideoIcon" style="visibility: hidden;">
                        <a href="javascript:;" (click)="goToMeeting()"><img class="whiteIcon cameraSize" src="assets/images/videoCamera.svg" alt=""></a>
                    </li>
                    <li class="nav-item pr-3 pt-2" [hidden]="showVideoIcon" style="visibility: hidden;" >
                        <a href="javascript:;" class="text-white" ><b>Pass:{{ejpass}}</b></a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle waves-effect waves-light" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <ng-container *ngIf="userImage else userPlaceholder">
                                <img class="profileImage" [src]="userImage" alt="">
                            </ng-container>
                            <ng-template #userPlaceholder>
                                <img class="profileImage" src="/assets/images/user.png" alt="" style="background-color: white;">
                            </ng-template>
                        </a>
                        <div class="dropdown-menu dropdown-info" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item waves-effect waves-light isNavLink" routerLink="/user/profile">Profile</a>
                            <a class="dropdown-item waves-effect waves-light isNavLink" (click)="logout()" href="javascript:;">Logout</a>
                        </div>
                    </li>
                </ng-container>
                <ng-template #loginBlock>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link isNavLink rounded bg-light text-myDark mr-2" routerLink="/invitation/Code"><b>Join Meeting</b></a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link isNavLink rounded bg-light text-myDark mr-2" routerLink="/login"><b>Sign In</b></a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link isNavLink bg-primary rounded" routerLink="/register"><b>Create Account Free</b></a>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>
</nav>-->
<swal
    #recodingPlugin
    title="Alert"
    (afterClose)="onAfterClose()"
    html="If you want to record your session please install the extension given below and follow the instructions.<br/><br/><a style='color:#007bff !important' href='https://chrome.google.com/webstore/detail/recordrtc/ndcljioonkecdnaaihodjgiliohngojp' target='_blank'><h4>Record RTC</h4></a>"
    type="warning">
</swal>
