import { Component, OnInit, OnDestroy, Inject, Renderer2, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject();
	loginForm: FormGroup;
    submitted = false;
    isDemo = "";
    showPassword=false;
    
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private authService: AuthService,
        private route: ActivatedRoute,
		private toastr: ToastrService,
		private fb: FormBuilder,
		private router: Router,
	) {this.authService.logout();}

    ngOnInit(): void {
        // this.authService.observable().subscribe(user=>{
        //     console.log('ejtimaa-user', user);
        //     if(user){
        //         this.router.navigate(['meeting/start']).then(() => {
        //             this.toastr.success('Sucessfully login', '');
        //         });
        //     }
        //     // this.ref.detectChanges();
        // })

        this.renderer.addClass(this.document.body, 'bg-light');
        this.isDemo = localStorage.getItem("isDemo");
        setTimeout(()=>{                     
            localStorage.isDemo = "";
       }, 3000);
        this.route.pathFromRoot[1].url.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
            if(val[0].path == 'verification') {
                const data = {
                    id: val[1].path,
                    verification_token: val[2].path
                }
                this.authService.verification(data).pipe(takeUntil(this.unsubscribe$))
                .subscribe(data => {
                    this.toastr.success(data.message, '');
                },
                    error => {
                        this.toastr.error(error.message, '');
                    }
                );
            }
        });
        this.loginForm = this.fb.group({
            email: ['',
                [
                    Validators.required,
                    Validators.maxLength(40)
                ]
            ],
            password: ['',
                [
                    Validators.required,
                    Validators.maxLength(40)
                ]
            ]
        });
    }

    get formControls() {
		return this.loginForm.controls;
	}
    toggleShow() {
        this.showPassword = !this.showPassword;
      }
    onSubmit() {
        this.submitted = true;
        this.authService.login(this.loginForm.value).pipe(takeUntil(this.unsubscribe$))
        .subscribe(data => {
            this.loginForm.reset();
            this.router.navigate(['meeting/start']).then(() => {
                this.toastr.success(data.message, '');
            });
        },
            error => {
                this.toastr.error(error.message, '');
            }
        ).add(() => {
			this.submitted = false;
        }); 
    }
    
    async googleSignin(){
      this.authService.googleSignIn(this.unsubscribe$)
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'bg-light');
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
