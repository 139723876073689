import { Component, OnInit } from "@angular/core";
import { Router,NavigationEnd } from "@angular/router";
import { AuthService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
    private unsubscribe$ = new Subject();
    loggedUser: any;
    constructor(private router: Router,
        private authService: AuthService,
    ) { }
     
    ngOnInit(): void {
        // this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
		// 	// if (event instanceof NavigationEnd) {
		// 	// 	this.loggedUser = this.authService.getUser();
              
		// 	// }
        //     this.loggedUser = this.authService.getUser();
           
		// });
        this.loggedUser = this.authService.getUser();
           
           
       
    }

    handleClick(): void {
		this.router.navigate(['/register']);
    }
    startDemo() { localStorage.isDemo = "demo"; }
}
