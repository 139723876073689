import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeetingService, AuthService, StorageService } from 'src/app/services';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
    selector: "app-invitation",
    templateUrl: "./invitation.component.html",
    styleUrls: ["./invitation.component.css"],
})
export class InvitationComponent implements OnInit, OnDestroy {

    @ViewChild('authSwal',{ static: true }) private authSwal: SwalComponent;
    private unsubscribe$ = new Subject();
	joinForm: FormGroup;
    submitted  = false;
    isLoggedIn = false;
    screenHeight:any;
    
    constructor(
        private meetingService: MeetingService,
        private storageService: StorageService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
		private fb: FormBuilder,
		private router: Router
    ) {}

    ngOnInit(): void {
        this.screenHeight = window.innerHeight; 
        this.isLoggedIn = this.authService.isLoggedIn();
        if(!this.isLoggedIn) {
            this.storageService.clear();
        }
        this.joinForm = this.fb.group({
            slug: ['',
                [
                    Validators.required,
                    Validators.maxLength(20)
                ],
                this.checkMeetingSlug.bind(this)
            ]
        });
        this.route.paramMap.pipe(takeUntil(this.unsubscribe$)).subscribe(paramMap => {
			this.joinForm.get('slug').setValue(paramMap.get('slug'));
		})
    }

    private checkMeetingSlug(control: FormControl) {
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => {
				this.meetingService.checkMeetingSlug(control.value).pipe(takeUntil(this.unsubscribe$))
				.subscribe(() => {
                    resolve(null);
                },
                () => {
                    resolve({ invalid_slug: true });
                });
            }, 500);
        });
        return promise;
	}

    public swalInputValidator(value: string): string | null | Promise<string | null> {
        if(!value)
        return 'Please enter your name';
    }

    get formControls() {
		return this.joinForm.controls;
    }

    onSubmit() {
        if(!this.isLoggedIn ||this.formControls.slug.errors) {
            this.authSwal.fire();
        } else {
            // console.log("this.else",this.joinForm.get('slug').value)
             window.location.href=window.location.protocol + "//" + window.location.host+`/meeting/room/${this.joinForm.get('slug').value}`
            this.router.navigate([`/meeting/room/${this.joinForm.get('slug').value}`]);
        }
    }

    handleSwalSubmit(name): void {
        const data = {
            name,
            slug: this.joinForm.get('slug').value
        };
        this.meetingService.joinAsGuest(data).pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              
            this.router.navigate([`/meeting/room/${this.joinForm.get('slug').value}`]);
        },
        (err) => {
            this.toastr.error(err.message, '');
        });
    }

    ngOnDestroy(): void {
     	this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
