import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { StorageService } from "src/app/services";

@Injectable({
  providedIn: "root",
})
export class MeetingService {
  serverUrl = environment.baseUrl;
  errorData: {};
  redirectUrl: string;
  @Output() onStartMeeting = new EventEmitter();

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  getUsers(search?: any) {
    let url = `${this.serverUrl}/users`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
  getAdminList(user_id?: any) {
    return this.http
      .get<any>(`${this.serverUrl}/get_alladmin?user_id=${user_id}`)
      .pipe(catchError(this.handleError));
  }
  getMeetingDetails() {
    return this.http
      .get<any>(`${this.serverUrl}/new_meeting_details`)
      .pipe(catchError(this.handleError));
  }

  MeetingsStart(playload){
    return this.http
    .post<any>(`${this.serverUrl}/start_meeting`, playload)
    .pipe(catchError(this.handleError));
  }

  getUpcomingMeetings(search?: string) {
    let url = `${this.serverUrl}/upcoming_meetings`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
  GetMeetingsStart(data) {
    return this.http
    .post<any>(`${this.serverUrl}/check_meeting_start`, data)
    .pipe(catchError(this.handleError));
  }

  getMeetingHistory(search?: any) {
    let url = `${this.serverUrl}/meeting_history`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  getChannelList(search?: string) {
    let url = `${this.serverUrl}/get_channel`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }

  todaysMeetings() {
    return this.http
      .get<any>(`${this.serverUrl}/todays_meetings`)
      .pipe(catchError(this.handleError));
  }

  meetingDetails(slug) {
    return this.http
      .get<any>(`${this.serverUrl}/meeting_details/${slug}`)
      .pipe(catchError(this.handleError));
  }

  sendInvitation(data) {
    return this.http
      .post<any>(`${this.serverUrl}/send_invitation`, data)
      .pipe(catchError(this.handleError));
  }

  inviteNewMember(data) {
    return this.http
      .post<any>(`${this.serverUrl}/add_new_member_channel`, data)
      .pipe(catchError(this.handleError));
  }
  getMembersList(search?: string) {
    let url = `${this.serverUrl}/get_membars`;
    if (search) {
      url += `?search=${search}`;
    }
    return this.http.get<any>(url).pipe(catchError(this.handleError));
  }
  inviteNewAdmin(data) {
    return this.http
      .post<any>(`${this.serverUrl}/add_new_admin`, data)
      .pipe(catchError(this.handleError));
  }

  getNewAdmin(data) {
    return this.http
      .post<any>(`${this.serverUrl}/get_alladmin`, data)
      .pipe(catchError(this.handleError));
  }

  deleteMeeting(id) {
    return this.http
      .get<any>(`${this.serverUrl}/delete_meeting/${id}`)
      .pipe(catchError(this.handleError));
  }

  // this is only sending slug in params with get
  /*closeMeeting(slug) {
        return this.http.get<any>(`${this.serverUrl}/close_meeting/${slug}`)
        .pipe(
            catchError(this.handleError)
        );
    }*/

  // this is post method with all data
  closeMeeting(data) {
    return this.http
      .post<any>(`${this.serverUrl}/close_meeting`, data)
      .pipe(catchError(this.handleError));
  }

  scheduleMeeting(data) {
    return this.http
      .post<any>(`${this.serverUrl}/schedule_meeting`, data)
      .pipe(catchError(this.handleError));
  }

  createChannel(data) {
    return this.http
      .post<any>(`${this.serverUrl}/create_channel`, data)
      .pipe(catchError(this.handleError));
  }
  updateChannel(data) {
    return this.http
      .post<any>(`${this.serverUrl}/update_channel`, data)
      .pipe(catchError(this.handleError));
  }
  updateInvitedMember(data) {
    return this.http
      .post<any>(`${this.serverUrl}/update_invited_member`, data)
      .pipe(catchError(this.handleError));
  }
  deleteInvitedMember(data) {
    return this.http
      .post<any>(`${this.serverUrl}/delete_invited_member`, data)
      .pipe(catchError(this.handleError));
  }
  joinAsGuest(data) {
    return this.http.post<any>(`${this.serverUrl}/join_as_guest`, data).pipe(
      map((resp) => {
        if (resp.user && resp.user.token) {
          this.storageService.set("ejtimaa-user", resp.user);
          return resp;
        }
      }),
      catchError(this.handleError)
    );
  }

  directMeeting(slug: any) {
    console.log("slug", slug);
    return this.http
      .post<any>(`${this.serverUrl}/direct_meeting`, { slug })
      .pipe(catchError(this.handleError));
  }

  checkMeetingSlug(slug) {
    return this.http
      .post<any>(`${this.serverUrl}/check_meeting_slug`, { slug })
      .pipe(catchError(this.handleError));
  }

  getMeeting() {
    const meeting = this.storageService.get("ejtimaa-meet");
    return meeting;
  }

  handleStartMeeting() {
    // console.log("onStartMeeting", this.onStartMeeting.emit());
    this.onStartMeeting.emit();
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error("An error occurred:", errorResponse.error.message);
    } else {
      console.error(
        `Backend returned code ${errorResponse.error.status}, ` +
          `body was: ${errorResponse.error.message}`
      );
    }
    this.errorData = {
      error: errorResponse.error.error,
      message: errorResponse.error.message
        ? errorResponse.error.message
        : "Something went wrong",
    };
    if (errorResponse.status == 401) {
      localStorage.removeItem("currentUser");
    }
    return throwError(this.errorData);
  }
}
