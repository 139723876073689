import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkAuthState(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  checkAuthState(url: string) {
    let params = new URLSearchParams(window.location.search);

    const email = params.get("email");
    const meeting_id = params.get("subId");

    const user = this.authService.getUser();
    if ((user && user.is_guest == false) || (user && user.token)) {
      return true;
    }
    if (user && user.is_guest == true && url.includes("room")) {
      return true;
    }

    console.log('url:::',url);
    // this.authService.redirectUrl = url;
   
    if(email&&meeting_id){
      sessionStorage.invitedDetails = JSON.stringify({
        inviteEmail: email,
        meeting_id,
      });
      this.router.navigate(["/invitedregister"], { queryParams: { returnUrl: url } });
    }else{
    this.router.navigate(["/login"], { queryParams: { returnUrl: url } });
    }
  }
}
