
<!-- Hero Section -->
<div class="jumbotron jumbotron-fluid hero-jumbotron py-2 mb-5">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-5 col-12 my-auto order-2 order-xl-1 order-lg-1">
                <h1 class="hero-heading text-white">Free virtual meeting</h1>
                <p class="hero-para text-white mb-0 mt-3">
                    Open for any type of online meeting, classes and keeping you connected wherever and whenever across the globe on any device at your convenience.
                </p>
                <div class="row my-4">
                    <div class="col-xl-8 col-lg-8 col-12 offset-xl-2 offset-lg-2">
                        <hr class="text-white" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-12 text-center">
                        <button type="button" class="btn btn-orange px-4 me-3"*ngIf="!loggedUser" routerLink="/fordemo" >Request a Demo</button>
                        <button type="button" class="btn btn-orange px-4 me-3" *ngIf="loggedUser" routerLink="/meeting/start" >Request a Demo</button>
                        <button type="button" class="btn btn-outline-light px-5"  *ngIf="!loggedUser" routerLink="/register">Get Started</button>
                        <button type="button" class="btn btn-outline-light px-5"  *ngIf="loggedUser" routerLink="/meeting/start">Get Started</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-12 offset-xl-1 offset-lg-1 order-1 order-xl-2 order-lg-2">
                <img src="assets/uploads/hero-devices.png" alt="" class="img-fluid hero-devices" />
            </div>
        </div>
    </div>
</div>
<!-- End -->

<!-- Why Ejtimaa -->
<div class="container my-5 pt-5">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-12">
            <h1 class="heading-50-semibold text-center mt-5 pt-4">Why Ejtimaa</h1>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-xl-4 col-lg-4 col-12">
            <div class="card text-center card-shadow">
                <div class="card-body">
                    <img src="assets/uploads/screen-sharing.svg" alt="" class="img-fluid img-150 my-2" />
                    <h1 class="heading-30-semibold">Screen Sharing</h1>
                    <p class="font-16 mt-4 px-4 mb-0">
                        Keep attendees engaged with simple and intuitive screen sharing.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-12">
            <div class="card text-center card-shadow">
                <div class="card-body">
                    <img src="assets/uploads/ejtimaa-messaging.svg" alt="" class="img-fluid img-150 my-2" />
                    <h1 class="heading-30-semibold">Ejtimaa Messaging</h1>
                    <p class="font-16 mt-4 px-4 mb-0">
                        Send public and private messages, use emojis to express yourself.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-12">
            <div class="card text-center card-shadow">
                <div class="card-body">
                    <img src="assets/uploads/video-camera.svg" alt="" class="img-fluid img-150 my-2" />
                    <h1 class="heading-30-semibold">Video / Audio</h1>
                    <p class="font-16 mt-4 px-4 mb-0">
                        Hold visual meetings and communicate using high quality audio.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->

<!-- What can you do with an Ejtimaa -->
<div class="container mb-5">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-12">
            <h1 class="heading-50-semibold text-center mt-5">What can you do with Ejtimaa</h1>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <app-add-to-calendar [startDate]='"04/25/2022"' [startTime]='"5:00 am"' [endDate]='"04/25/2022"' [endTime]='"8:00 pm"' [title]='"Test Demo Sheduler"' [details]='"My meeting.edumeet.com"' [location]='"The Bar, New York, NY"'></app-add-to-calendar>
        </div>
    </div> -->
    <div class="row mt-5">
        <div class="col-xl-8 col-lg-8 col-12 offset-xl-2 col-lg-2">
            <div class="card full-width-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-12 my-auto">
                            <img src="assets/uploads/conversation.svg" alt="" class="img-fluid" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-12 my-auto">
                            <h1 class="heading-40-semibold">Get the conversation started</h1>
                            <p class="font-16 mb-0">
                                Whether you're communicating with a business client or seeing someone you care about, Ejtimaa makes it easy to connect.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card full-width-card mt-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-12 my-auto">
                            <img src="assets/uploads/push-message.svg" alt="" class="img-fluid" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-12 my-auto">
                            <h1 class="heading-40-semibold">Send instant push message</h1>
                            <p class="font-16 mb-0">
                                You can effectively use Ejtimaa Chat features across a wide range of communication options, including meetings, rooms, and webinars. Information and knowledge at your fingertips.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card full-width-card mt-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-12 my-auto">
                            <img src="assets/uploads/feedback.svg" alt="" class="img-fluid" />
                        </div>
                        <div class="col-xl-10 col-lg-10 col-12 my-auto">
                            <h1 class="heading-40-semibold">Gather real-time feedback</h1>
                            <p class="font-16 mb-0">
                                Throughout the meeting. Do not lose momentum once the meeting is over. Sharing your meeting recordings, chat, or session is as easy as 1-2-3
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->

<!-- Interested? -->
<div class="container-fluid interested-background py-4">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 col-lg-8 col-12 offset-xl-2 offset-lg-2 text-center">
                <h1 class="heading-50-semibold text-white mt-4">Interested?</h1>
                <p class="para-30 mt-4 text-white">
                    Fill out the form on this page to schedule your personal demo of our conference app!
                </p>
                <button type="button" routerLink="/fordemo" class="btn btn-light px-4 my-4">LEARN MORE</button>
            </div>
        </div>
    </div>
</div>
<!-- End -->

<!-- Getting Started -->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-5 col-lg-5 col-12 offset-xl-1 offset-lg-1 py-5 my-auto">
            <h1 class="heading-50-semibold">Getting Started</h1>
            <ul class="getting-started-ul-list my-5">
                <li>
                    <img src="assets/uploads/list-arrow.svg" alt="" class="img-fluid"> Sign up and activate your Ejtimaa Account.
                </li>
                <li>
                    <img src="assets/uploads/list-arrow.svg" alt="" class="img-fluid"> Click on the Sign In button if you already have an existing account.
                </li>
                <li>
                    <img src="assets/uploads/list-arrow.svg" alt="" class="img-fluid"> Once Signed In, click "Copy Invitation" to share to your friends or group.
                </li>
                <li>
                    <img src="assets/uploads/list-arrow.svg" alt="" class="img-fluid"> Then click "New Meeting" button from the default screen.
                </li>
                <li>
                    <img src="assets/uploads/list-arrow.svg" alt="" class="img-fluid"> Enter a code and start your meeting.
                </li>
            </ul>
            <!--<button type="button" class="btn btn-dark px-4">LEARN MORE</button>-->
        </div>
        <div class="col-xl-6 col-lg-6 col-12 video-background">
            <video width="100%" height="100%" controls>
                <source src="assets/uploads/Ejtimaa_homepage.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
                Your browser does not support the video tag.
              </video>
            <!-- <a href="" class="play-anchor">
                <img src="assets/uploads/play-icon.svg" alt="" class="img-fluid play-img">
            </a> -->
        </div>
    </div>
</div>
<!-- End -->
