import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError, takeUntil } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { StorageService } from './storage.service';
import {GoogleAuthService} from "ng-gapi";
import GoogleUser = gapi.auth2.GoogleUser;
import {  Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
 const suth={
    token_type: "Bearer",
    access_token: "ya29.A0ARrdaM-oEoL4PZktJjP3BJXmVY-6gJ7H-tnQQX3A5y4HTCQv8Sl7dYae_bvT4WcHNlW-YVXClBKeDxAs0WIxAIF7DzHhNUK4GGhb-YCTrZBydqXeCKHFOo3SEdlnFRkkq7XDHnFYI1-_siqoD3epYlhoHNBJBA",
    scope: "email profile https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly openid https://www.googleapis.com/auth/userinfo.email",
    
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
    serverUrl = environment.baseUrl;
	errorData: {};
    redirectUrl: string;
    private user: any;

	constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private googleAuthService: GoogleAuthService,
        private ngZone: NgZone,
        private router: Router,
		private toastr: ToastrService,
    ) {}

    public setUser(user: GoogleUser) {
        this.user = user
       return  user;
    }

    public getCurrentUser(): GoogleUser {
        return this.user;
    }

    googleSignIn(unsubscribe) {
       return this.googleAuthService.getAuth().subscribe((auth) => {
           return auth.signIn().then(res => this.signInSuccessHandler(res,unsubscribe), err => this.signInErrorHandler(err));
        });
    }

    private signInSuccessHandler(res: GoogleUser,unsubscribe) {
       return this.ngZone.run(() => {
            
            let profile = res.getBasicProfile();

            this.user = {
                token:res.getAuthResponse().id_token,
                user_id:profile.getId(),
                id:profile.getId(),
                first_name:profile.getName(),
                image_url:profile.getImageUrl(),
                email:profile.getEmail(),
                is_guest:false
            };
            const register = {
                token:res.getAuthResponse().id_token,
                user_id:profile.getId(),
                first_name:profile.getName(),
                image_url:profile.getImageUrl(),
                email:profile.getEmail(),
                password: Math.random().toString(36).slice(2, 10),
                verified:true,
                is_guest:false,
                is_googleSignIn:true,
            };

            if(res.isSignedIn()){
                // this.storageService.set('ejtimaa-user', this.user);
                this.register(register).pipe(takeUntil(unsubscribe))
                .subscribe(data => {
                    if(data.status){
                        // console.log('data',data);
                        const newUser=data?.user;
                        this.googleUserLogin(newUser).pipe(takeUntil(unsubscribe))
                        .subscribe(data1 => {
                            console.log('data',data1);
                            this.router.navigate(['meeting/start']).then(() => {
                                this.toastr.success(data.message, '');
                            });
                        },
                            error => {
                                this.toastr.error(error.message, '');
                            }
                        ).add(() => {
                            return false;
                        }); 
                    }else{
                        this.toastr.error(data.message, '');
                    }
                  
                },
                    error => {
                        this.toastr.error(error.message, '');
                    }
                ).add(() => {
                   return false;
                }); 
                return res.getAuthResponse();
            }
           else{
            throwError({error:'error',message:'Login failed'})
            console.log("else",res.getAuthResponse())
        }
        });
    }
    private signInErrorHandler(err) {
        return null;
    }
    //TODO: Rework
    public signOut(): void {
        this.googleAuthService.getAuth().subscribe((auth) => {
            try {
                auth.signOut();
            } catch (e) {
                console.error(e);
            }
            
        });
    }

	register(user) {
        return this.http.post<any>(`${this.serverUrl}/register`, user)
            .pipe(
                catchError(this.handleError)
            );
    }

    requestDemoLink(user) {
        return this.http.post<any>(`${this.serverUrl}/requestDemoLink`, user)
            .pipe(
                catchError(this.handleError)
            );
    }
    
    login(user) {
        return this.http.post<any>(`${this.serverUrl}/login`, user)
            .pipe(map(resp => {
                if (resp.user && resp.user.token) {
                    this.storageService.set('ejtimaa-user', resp.user);
                    return resp;
                }
            }),
                catchError(this.handleError)
            );
    }

    googleUserLogin(token) {
      
        return this.http.post<any>(`${this.serverUrl}/googleLogin`, token).pipe(map(resp => {
            if (resp.user && resp.user.token) {
                this.storageService.set('ejtimaa-user', resp.user);
                
                return resp;
            }
        }),
            catchError(this.handleError)
        );
    }


    verification(data) {
        return this.http.post<any>(`${this.serverUrl}/verification`, data)
            .pipe(
                catchError(this.handleError)
            );
    }

    forgotPassword(user) {
        
        return this.http.post<any>(`${this.serverUrl}/forgot_password`, user)
            .pipe(
                catchError(this.handleError)
            );
    }

    resetPassword(password) {
        return this.http.post<any>(`${this.serverUrl}/reset_password`, password)
            .pipe(
                catchError(this.handleError)
            );
    }

    checkResetLink(token){
        return this.http.post<any>(`${this.serverUrl}/check_reset_link`, { token })
            .pipe(
                catchError(this.handleError)
            );
    }

    uniqueCheck(control, type) {
        return this.http.post<any>(`${this.serverUrl}/unique_check`, { control, type })
            .pipe(
                catchError(this.handleError)
            );
    }

    userExist(control) {
        return this.http.post<any>(`${this.serverUrl}/user_exist`, { control })
            .pipe(
                catchError(this.handleError)
            );
    }

    userProfile() {
        return this.http.get<any>(`${this.serverUrl}/profile`)
        .pipe(
            catchError(this.handleError)
        );
    }

    updateProfile(data) {
        return this.http.post<any>(`${this.serverUrl}/profile`,data)
        .pipe(map(resp => {
            if (resp.user) {
                const user = this.storageService.get('ejtimaa-user');
                user.first_name = resp.user.first_name;
                user.last_name  = resp.user.last_name;
                user.image_url  = resp.user.image_url;
                this.storageService.set('ejtimaa-user', user);
            }
            return resp;
        }),
            catchError(this.handleError)
        );
    }

    updatePassword(data) {
        return this.http.post<any>(`${this.serverUrl}/update_password`, data)
        .pipe(
            catchError(this.handleError)
        );
    }

    isLoggedIn() {
        if (this.storageService.get("ejtimaa-user")) {
            return true;
        }
        return false;
    }

    getUser() {
        const loggedUser = this.storageService.get("ejtimaa-user");
        return loggedUser;
    }

    getAuthorizationToken() {
        const loggedUser = this.storageService.get("ejtimaa-user");
        return loggedUser.token;
    }

    logout() {
        this.storageService.clear();
        // localStorage.isDemo = "";
    }

    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.log('An error occurred:', errorResponse.error.message);
        } else {
            console.log(`Backend returned code ${errorResponse.error.status}, ` + `body was: ${errorResponse.error.message}`);
        }
        this.errorData = {
            error: errorResponse.error.error,
            message: (errorResponse.error.message) ? errorResponse.error.message : 'Something went wrong'
        };
        console.log("errorResponse.error",errorResponse.error);
        
        if(errorResponse.status == 401){
            localStorage.removeItem('loggedUser');
        }
        return throwError(this.errorData);
    }

}
