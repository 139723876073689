import { NgModule } from "@angular/core";
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { RegisterComponent } from './components/register/register.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DemoComponent } from './components/demo/demo.component';
import { AuthGuard } from './guards/auth.guard';
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { InvitedRegisterComponent } from "./components/invitedregister/invitedregister.component";

const routes: Routes = [
	{
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    {
        path: 'contact',
        component: ContactUsComponent,
        pathMatch: 'full'
    },
    {
        path: 'register', 
        component: RegisterComponent,
        pathMatch: 'full'
    },  {
        path: 'invitedregister', 
        component: InvitedRegisterComponent,
        pathMatch: 'full'
    },  {
        path: 'fordemo', 
        component: DemoComponent,
        pathMatch: 'full'
    },
    {
        path: 'forgot_password',
        component: ForgotPasswordComponent,
        pathMatch: 'full'
    },
    {
        path: 'reset_password/:token',
        component: ResetPasswordComponent,
        pathMatch: 'full'
    },
    {
        path: 'verification/:id/:token',
        component: LoginComponent,
        pathMatch: 'full'
	},
    
	{
        path: 'meeting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/meeting/meeting.module')
            .then(mod => mod.MeetingModule)
    },
    {
        path: 'user',
        canActivate: [AuthGuard],
        loadChildren: () => import('./components/user/user.module')
            .then(mod => mod.UserModule)
    },
    {
        path: 'invitation/:slug',
        component: InvitationComponent,
        pathMatch: 'full'
	},{ path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
    RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: 'reload'
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
