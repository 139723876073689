<!-- Sign In Form -->
<div class="container my-5 py-5">
    <div class="row mt-5 mb-5">
        <div class="col-xl-4 col-lg-6 col-md-6 col-12 offset-xl-4 offset-lg-4 offset-md-6 mb-5">
            <div class="card access-form pb-2">
                <div class="card-header text-center py-4">
                    <!-- <img src="assets/uploads/logo.png" alt="" class="img-fluid logo"> -->
                    <h1 class="heading-24-semibold text-white mb-0 mt-2">SIGN UP</h1>
                </div>
                <div class="card-body px-4">
                    <form class="mt-3" [formGroup]="registerForm"
                    (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control"  formControlName="first_name" id="firstname-field"
                                        placeholder="First Name">
                                    <label for="firstname-field">First Name*</label>
                                    <!--<span class="input-span">Required</span>-->
                                    <div *ngIf="formControls.first_name.invalid && (formControls.first_name.dirty || formControls.first_name.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.first_name.errors.required"> Please
                                                    enter your first name. </ng-container>
                                                <ng-container *ngIf="formControls.first_name.errors.maxlength">
                                                    Max characters limit is {{
                                                    formControls.first_name.errors.maxlength.requiredLength }}
                                                    characters.
                                                </ng-container>
                                            </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" formControlName="last_name" id="lastname-field"
                                        placeholder="Last Name">
                                    <label for="lastname-field">Last Name* </label>
                                    <!-- <span class="input-span">Required</span> -->
                                    <div *ngIf="formControls.last_name.invalid && (formControls.last_name.dirty || formControls.last_name.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.last_name.errors.required"> Please
                                                    enter your last name. </ng-container>
                                                <ng-container *ngIf="formControls.last_name.errors.maxlength">
                                                    Max characters limit is {{
                                                    formControls.last_name.errors.maxlength.requiredLength }}
                                                    characters.
                                                </ng-container>
                                            </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="email" formControlName="email" class="form-control" id="email-field"
                                        placeholder="john@example.com">
                                    <label for="email-field">E-Mail* </label>
                                    <!-- <span class="input-span">Required</span> -->
                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)"
                                        class="error">
                                        <ng-container *ngIf="formControls.email.errors.required"> Please enter your
                                            email. </ng-container>
                                        <ng-container
                                            *ngIf="formControls.email.errors.email || formControls.email.errors.pattern">
                                            Please enter a valid email address. </ng-container>
                                        <ng-container *ngIf="formControls.email.errors.email_unique"> Email already
                                            exist. </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <i data-feather="eye" style="cursor: pointer"></i>
                                    <input *ngIf="showPassword" type="text" aria-describedby="materialRegisterFormPasswordHelpBlock"  formControlName="password" class="form-control" id="password-field"
                                        placeholder="Password">
                                    <input *ngIf="!showPassword" type="password" aria-describedby="materialRegisterFormPasswordHelpBlock"  formControlName="password" class="form-control" id="password-field"
                                        placeholder="Password">
                                    <label for="password-field">Password* </label>
                                    <span class="input-span cursor-pointer" (click)="toggleShow()">
                                        <i *ngIf="showPassword" class="fas fa-eye" ></i>
                                        <i *ngIf="!showPassword" class="fas fa-eye-slash" ></i>
                                    </span>
                                    <div *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.password.errors.required"> Please
                                                    enter your password. </ng-container>
                                                <ng-container *ngIf="formControls.password.errors.minlength">
                                                    Please enter at least {{
                                                    formControls.password.errors.minlength.requiredLength }} characters.
                                                </ng-container>
                                                <ng-container *ngIf="formControls.password.errors.maxlength">
                                                    Please enter no more than {{
                                                    formControls.password.errors.maxlength.requiredLength }} characters.
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="formControls.password.errors.pattern && !formControls.password.errors.minlength">
                                                    Password should contains a lowercase, a uppercase character and a
                                                    digit.
                                                </ng-container>
                                            </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="d-grid gap-2">
                                    <button type="button" class="btn btn-orange btn-block" type="submit"
                                    [disabled]="!registerForm.valid || submitted">Sign Up</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12 text-center">
                                <p class="mb-0 font-14">Do you already have an Ejtimaa account? <a routerLink="/login">Sign In</a></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End -->
<!--<div style="background-image: url('assets/images/pexels-anna-shvets-4226140@2x.png');background-position: center;background-repeat: no-repeat; 
background-size: cover; ">
    <div class=" bg-primary" style="opacity: 0.8;">
        <div class="container ">
            <div style="text-align: center;">
                <h6 class="heading pt-3">Ejitmaa |Sign Up</h6>
            </div>
            <div class="row mt-5 pb-4">
                <div class="col-lg-6 col-sm-6 col-12 offset-lg-3 offset-sm-3">
                    <div class="card">
                        <!-- <h5 class="card-header dark-text text-center py-4">
                    <strong>Registration</strong>
                </h5> --
                        <div class="card-body pt-0">
                            <form class="text-center" style="color: #757575" [formGroup]="registerForm"
                                (ngSubmit)="onSubmit()">
                                <br>
                                <div class="form-row">
                                    <div class="col">
                                        <div>
                                            <!-- <div class="md-form"> --
                                            <label class="label_class">First name</label>
                                            <input type="text" formControlName="first_name"
                                                id="materialRegisterFormFirstName" class="form-control"
                                                placeholder="First name" maxlength="40" />

                                            <div *ngIf="formControls.first_name.invalid && (formControls.first_name.dirty || formControls.first_name.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.first_name.errors.required"> Please
                                                    enter your first name. </ng-container>
                                                <ng-container *ngIf="formControls.first_name.errors.maxlength">
                                                    Max characters limit is {{
                                                    formControls.first_name.errors.maxlength.requiredLength }}
                                                    characters.
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div>
                                            <!-- <div class="md-form"> --
                                            <label class="label_class">Last name</label>
                                            <input type="text" formControlName="last_name"
                                                id="materialRegisterFormLastName" placeholder="Last name"
                                                class="form-control" maxlength="40" />

                                            <div *ngIf="formControls.last_name.invalid && (formControls.last_name.dirty || formControls.last_name.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.last_name.errors.required"> Please
                                                    enter your last name. </ng-container>
                                                <ng-container *ngIf="formControls.last_name.errors.maxlength">
                                                    Max characters limit is {{
                                                    formControls.last_name.errors.maxlength.requiredLength }}
                                                    characters.
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="mt-0">
                                    <label class="label_class">Email Adress</label>
                                    <input type="email" formControlName="email" id="materialRegisterFormEmail"
                                        placeholder="Email Adress" class="form-control" />

                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)"
                                        class="error">
                                        <ng-container *ngIf="formControls.email.errors.required"> Please enter your
                                            email. </ng-container>
                                        <ng-container
                                            *ngIf="formControls.email.errors.email || formControls.email.errors.pattern">
                                            Please enter a valid email address. </ng-container>
                                        <ng-container *ngIf="formControls.email.errors.email_unique"> Email already
                                            exist. </ng-container>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col">
                                        <!-- <div class="md-form"> --
                                        <br>
                                        <div>

                                            <label class="label_class">Password</label>
                                            <input type="password" formControlName="password"
                                                id="materialRegisterFormPassword" placeholder="Password"
                                                class="form-control"
                                                aria-describedby="materialRegisterFormPasswordHelpBlock" />

                                            <div *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.password.errors.required"> Please
                                                    enter your password. </ng-container>
                                                <ng-container *ngIf="formControls.password.errors.minlength">
                                                    Please enter at least {{
                                                    formControls.password.errors.minlength.requiredLength }} characters.
                                                </ng-container>
                                                <ng-container *ngIf="formControls.password.errors.maxlength">
                                                    Please enter no more than {{
                                                    formControls.password.errors.maxlength.requiredLength }} characters.
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="formControls.password.errors.pattern && !formControls.password.errors.minlength">
                                                    Password should contains a lowercase, a uppercase character and a
                                                    digit.
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <!-- <div class="md-form"> --
                                        <br>
                                        <div>
                                            <label class="label_class">Confirm Password</label>
                                            <input type="password" formControlName="confirm_password"
                                                id="materialRegisterFormPhone" class="form-control"
                                                placeholder="Confirm Password"
                                                aria-describedby="materialRegisterFormPhoneHelpBlock" />


                                            <div *ngIf="formControls.confirm_password.invalid && (formControls.confirm_password.dirty || formControls.confirm_password.touched)"
                                                class="error">
                                                <ng-container *ngIf="formControls.confirm_password.errors.required">
                                                    Please confirm your password.
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="formControls.confirm_password.errors.passwordNotMatch && !formControls.confirm_password.errors.required">
                                                    please enter same password.
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mt-2 " >
                                    <div class="col text-start">
                                <div class="form-check " style="float: left;">
                            <input type="checkbox" formControlName="accept" class="form-check-input" id="materialRegisterFormNewsletter" />
                            <label class="form-check-label text-white" for="materialRegisterFormNewsletter">I accept the Terms and Conditions</label>
                            <div *ngIf="formControls.accept.invalid && (formControls.accept.dirty || formControls.accept.touched) " class="error">
                                <div *ngIf="formControls.accept.errors.required ">
                                    Please accept the Terms and Conditions.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                                <button class="btn-rounded btn-block my-4 waves-effect z-depth-0 p-2 bg-primary text-white" type="submit"
                                    [disabled]="!registerForm.valid || submitted">Sign Up</button>

                                <p class="p_tag">
                                    Ejtimaa is protected by reCAPTCHA and the Privacy Policy and Terms of
                                </p>
                                <p class="p_tag">
                                    Already a member?
                                    <a routerLink="/login" class="signin">Sign in</a>
                                </p>

                                <p class="p_tag">By signing in, I agree to the <span class="signin">Privacy
                                        Policy</span> and <span class="signin">Terms of Service. </span></p>
                                <!-- <p>or sign up with:</p>

                        <a type="button" class="btn-floating btn-fb btn-sm">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a type="button" class="btn-floating btn-tw btn-sm">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a type="button" class="btn-floating btn-li btn-sm">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a type="button" class="btn-floating btn-git btn-sm">
                            <i class="fab fa-github"></i>
                        </a>

                        <hr />
                        <p>
                            By clicking <em>Sign up</em> you agree to our
                            <a href="" target="_blank">terms of service</a>
                        </p> --
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->