import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Router,CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

declare let Email: any;
@Component({
    selector: "app-register",
    templateUrl: "./demo.component.html",
    styleUrls: ["./demo.component.css"],
})
export class DemoComponent implements OnInit, OnDestroy {

	private unsubscribe$ = new Subject();
	registerForm: FormGroup;
	submitted = false;
	loggedUser: any;
    constructor(
		private authService: AuthService,
		private toastr: ToastrService,
		private fb: FormBuilder,
        private router: Router,
        private location: Location,
        
	) { this.authService.logout();   }

    ngOnInit(): void {
       
                    this.registerForm = this.fb.group({
                        name: ['',
                            [
                                Validators.required,
                                Validators.maxLength(40)
                            ]
                        ],
                        company_name: ['',
                            [
                                Validators.required,
                                Validators.maxLength(40)
                            ]
                        ],
                        email: ['',
                            [
                                Validators.required,
                                Validators.email,
                                Validators.maxLength(40),
                                Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
                            ],
                            this.isEmailUnique.bind(this)
                        ],
                        phone: ['',
                            [
                                Validators.required,
                                Validators.maxLength(13),
                            ]
                        ],
                       
                        accept: [false, Validators.requiredTrue],
                    });
               
	}

	get formControls() {
		return this.registerForm.controls;
	}

	private isEmailUnique(control: FormControl) {
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => {
				this.authService.uniqueCheck(control.value, 'email').pipe(takeUntil(this.unsubscribe$))
				.subscribe(() => {
                    resolve(null);
                }, () => { resolve({ email_unique: true }); });
            }, 500);
        });
        return promise;
	}

    
    onSubmit() {
       console.log("this.registerForm.value",this.registerForm.value)
        this.submitted = true;
        if(this.registerForm.value.name =="") {
            this.toastr.error("Enter Your Name", '');
        }
        else if (this.registerForm.value.company_name == "") {
            this.toastr.error("Enter company Name", '');
        }else if( this.registerForm.value.email==""){
            this.toastr.error("Enter valid Email", '');
        }else if(this.registerForm.value.phone=="") {
            this.toastr.error("Enter your phone number", '');
        } else {

            this.authService.requestDemoLink(this.registerForm.value).pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.registerForm.reset(); console.log("data", data);
                this.toastr.success(data.message, '');
            },
                error => {
                    this.toastr.error(error.message, '');
                }
            ).add(() => {
                this.submitted = false;
            });
            // Email.send({
            //     Host : `smtp.gmail.com`,
            //     Username : `ejtima@gmail.com`,
            //     Password : `Pa55w0rd@123`,
            //     To : `shravankashyap95@gmail.com`,
            //     From : `ejtima@gmail.com`,
            //     Subject : "Request for demo",
            //     Body : `<i>This is sent as a feedback from my resume page.</i> <br/> <b>Name: </b>${this.registerForm.value.name} <br/> <b>Company Name: </b>${this.registerForm.value.company_name} <br /> <b>Email: </b>${this.registerForm.value.email}<br /> <b>Subject: </b>${this.registerForm.value.phone}<br />`
            //     }).then( message => {alert(message);}).catch((err)=>console.log("errrr=>>>",err));
                  
                }

        }

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
