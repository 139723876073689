<div class="container my-5 py-5">
    <div class="row mt-5">
        <div class="col-xl-4 col-lg-4 col-12 offset-xl-4 offset-lg-4">
            <div class="card access-form pb-2">
                <div class="card-header text-center py-4">
                    <img src="assets/uploads/logo.png" alt="" class="img-fluid logo">
                    <h1 class="heading-24-semibold text-white mb-0 mt-2">FORGOT PASSWORD</h1>
                </div>

                <div class="card-body px-4">
                    <form class="mt-3" [formGroup]="forgotPasswordForm"
                        (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="form-floating">
                                    <input type="email" placeholder="E-mail" formControlName="email"
                                        id="materialLoginFormEmail" class="form-control" />
                                        <label for="email-field">E-Mail* </label>
                                    <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)"
                                        class="error">
                                        <div *ngIf="formControls.email.errors.required">
                                            Please enter your email.
                                        </div>
                                        <div
                                            *ngIf="formControls.email.errors.email || formControls.email.errors.pattern">
                                            please enter a valid email address.
                                        </div>
                                        <div *ngIf="formControls.email.errors.user_exist">
                                            User does not exist.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-xl-12 col-lg-12 col-12">
                                <div class="d-grid gap-2">
                                    <button class="btn btn-primary btn-block" type="submit"
                                        [disabled]="!forgotPasswordForm.valid || submitted">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>